function Tab({ title, activeTab, onChange }) {
  return (
    <button
      className={`py-3 px-6 rounded-md font-medium text-base transition-colors duration-200 ease-in-out
        ${
          activeTab
            ? "bg-dark-blue-200 text-white shadow-sm focus:ring-0"
            : "bg-gray-100 text-gray-700 hover:bg-gray-600 hover:text-white"
        } focus:outline-none focus-visible:ring-2 focus-visible:ring-dark-blue-200 focus-visible:ring-offset-2`}
      type="button"
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        onChange();
      }}
    >
      {title}
    </button>
  );
}

export default function TabNav({ tabs, onChange, activeTab }) {
  return (
    <div className="flex flex-row mt-4 gap-2">
      {tabs.map((tab, idx) => (
        <Tab title={tab} activeTab={activeTab === tab} onChange={() => onChange(tab)} key={idx} />
      ))}
    </div>
  );
}
