import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuthPrompt } from "../../features/auth/authHooks";
import {
  clearErrors,
  loginAsync,
  setLoginModalState,
  setPasswordAsync,
  sendOTPAsync,
  signinWithOTPAsync
} from "../../features/auth/authSlice";
import { clearErrors as clearUserErrors, upsertUserAsync } from "../../features/user/userSlice";

import axios from "axios";
import { flashDetailedToastNotification } from "../../features/toast-notification/toastNotificationSlice";
import LoadingSpinner from "../LoadingSpinner";

function InputField({
  value,
  onChange,
  name,
  label,
  type,
  required,
  autoFocus,
  tabIndex,
  placeholder
}) {
  return (
    <div className="mt-6">
      <label htmlFor={name} className="block text-sm font-medium leading-5 text-gray-900">
        {label}
      </label>
      <input
        id={name}
        name={name}
        type={type || "text"}
        required={required}
        autoFocus={autoFocus}
        tabIndex={tabIndex}
        placeholder={placeholder}
        className="mt-1 shadow-sm appearance-none block w-full px-3 py-3 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
        value={value}
        onChange={onChange}
      />
    </div>
  );
}

function ResetPasswordButton() {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(
      setLoginModalState({
        loginModalShowing: true,
        activeAuthForm: "resetPassword"
      })
    );
  };

  return (
    <div className="mt-2 flex items-center justify-center">
      <button
        className="font-medium text-blue-600 text-sm leading-5 hover:text-blue-500 focus:outline-none focus:underline transition ease-in-out duration-150"
        onClick={handleClick}
        type="button"
      >
        Forgot your password?
      </button>
    </div>
  );
}

const AuthForm = ({ formValues, setFormValues, initialFlow = "otp" }) => {
  const dispatch = useDispatch();
  const { dismissAuthPrompt } = useAuthPrompt();

  const [user, setUser] = useState(null);
  const [authFlowChosen, setAuthFlowChosen] = useState(initialFlow); // can be 'otp', 'password', 'signup', or 'setPassword'
  const [isOTPSent, setIsOTPSent] = useState(false); // OTP sent to user's email
  const [errorPassword, setErrorPassword] = useState();
  const [loading, setLoading] = useState(false);

  const { error } = useSelector(state => state.auth);
  const { platformData } = useSelector(state => state.frontend);

  // after the user enters their email there are 3 scenarios:
  const isOTPLoginChosen = authFlowChosen === "otp";
  const isPasswordLogin = authFlowChosen === "password";
  const isPasswordUpdate = authFlowChosen === "setPassword";
  const isAccountCreation = authFlowChosen === "signup";

  const titleText = isAccountCreation ? `Welcome to ${platformData?.name || ""}!` : "Welcome back!";
  const subText = isPasswordUpdate ? (
    "We're updating our security, please update your password below."
  ) : isAccountCreation ? (
    <>
      First, we'll setup a new account,
      <span style={{ display: "block" }}>then you can access your group.</span>
    </>
  ) : isOTPLoginChosen ? (
    "Enter your email and we'll send you a One-Time Code"
  ) : (
    "Let's sign in."
  );

  // clear out password value if user enters set password flow
  useEffect(() => {
    if (isPasswordUpdate) setFormValues({ email: user.email });
  }, [isPasswordUpdate, setFormValues, user]);

  const onChoosePasswordLogin = () => {
    setAuthFlowChosen("password");
    // this is a bit of a shortcut, if the user first enters their email, then switches from OTP to password login
    // then check if they have a password set, if not, prompt them to set a password instead of login
    // this is done redundantly in the onSubmit function, but it's done here as well to make the UI more responsive
    if (formValues.email) {
      setLoading(true);
      // validate user by email to see if the user has a password set
      axios
        .get(`/auth/user-validation`, {
          params: { email: formValues.email, PlatformId: platformData.id }
        })
        .then(resp => {
          const user = resp.data;
          // if the user exists in the db but doesn't have a password set
          // we need to prompt them to update their password before logging in
          setUser(user);
          if (user && !user.isPasswordSet) setAuthFlowChosen("setPassword");
        })
        .catch(err => {
          // don't do anything here, we'll handle this in the onSubmit function
        })
        .finally(() => {
          setLoading(false);
          // set timeout of 0 to put this in the event loop so that it's executed after the form is rendered
          // state updates are batched, so if we don't do this, we'll be trying to focus the input before it's rendered
          setTimeout(() => document.querySelector("[name=email]")?.focus(), 0);
        });
    }
  };

  const onChange = event => {
    dispatch(clearErrors());
    setErrorPassword();

    const { name, value } = event.target;
    setFormValues(prevState => ({ ...prevState, [name]: value }));
  };

  const onSubmit = async event => {
    event.preventDefault();

    if (isOTPLoginChosen && !isOTPSent) {
      try {
        const { data } = await axios.get(`/auth/user-validation`, {
          params: { email: formValues.email, PlatformId: platformData.id }
        });
        setUser(data);
        await sendOTPAsync({ UserId: data.id });
        dispatch(
          flashDetailedToastNotification({ details: "One-Time Password sent to your email" })
        );
        return setIsOTPSent(true);
      } catch (err) {
        if (err.response?.status === 404) {
          dispatch(flashDetailedToastNotification({ details: "User not found", type: "error" }));
        } else {
          dispatch(
            flashDetailedToastNotification({ details: "Something went wrong", type: "error" })
          );
          console.error("Failed user validation", err);
        }
        return;
      }
    }

    if (isOTPLoginChosen && isOTPSent) {
      try {
        const action = await dispatch(signinWithOTPAsync({ UserId: user.id, otp: formValues.otp }));
        if (action.type.includes("fulfilled")) dismissAuthPrompt();
      } catch (err) {
        console.error("Failed to sign in with OTP", err);
        // no need to show toast notification here, it's handled in the authSlice
      }
    }

    if (isPasswordUpdate) {
      try {
        const action = await dispatch(
          setPasswordAsync({ userId: user.id, password: formValues.password })
        );
        if ("auth/set-password/fulfilled" === action.type) {
          dismissAuthPrompt();
          dispatch(clearUserErrors());
          const userToUpdate = { id: user.id, status: "active", providerId: null };
          if (user?.id && !user.PlatformId) userToUpdate.PlatformId = platformData.id;
          await dispatch(upsertUserAsync({ user: userToUpdate }));
          await dispatch(loginAsync({ email: formValues.email, password: formValues.password }));
        }
      } catch (err) {
        console.error("Failed to set password", err);
      }
    }

    if (isPasswordLogin) {
      try {
        // validate user by email
        // the main purpose of this check is to see if the user has a password set
        // but it also has the effect of checking if the user exists in the db
        const { data } = await axios.get(`/auth/user-validation`, {
          params: { email: formValues.email, PlatformId: platformData.id }
        });
        // if the user exists in the db but doesn't have a password set
        // we need to prompt them to update their password before logging in
        setUser(data);
        if (data && !data.isPasswordSet) return setAuthFlowChosen("setPassword");

        // if the user exists in the db and has a password set, proceed with login
        const action = await dispatch(loginAsync({ ...formValues, PlatformId: platformData.id }));
        if ("auth/signin/fulfilled" === action.type) {
          setFormValues({}); // clear form values
          dismissAuthPrompt();
          return;
        }
      } catch (err) {
        if (err.response?.status === 404) {
          dispatch(flashDetailedToastNotification({ details: "User not found", type: "error" }));
        } else {
          dispatch(
            flashDetailedToastNotification({ details: "Something went wrong", type: "error" })
          );
          console.error("Failed user validation", err);
        }
        return;
      }
    }

    if (isAccountCreation) {
      const user = { ...formValues, role: "free" };
      if (user.password.length < 8)
        return setErrorPassword("Passwords must be at least 8 characters");

      const action = await dispatch(upsertUserAsync({ user, PlatformId: platformData.id }));
      if ("user/upsert/fulfilled" === action.type) {
        dismissAuthPrompt();
        dispatch(clearUserErrors());
        await dispatch(
          loginAsync({
            email: formValues.email,
            password: formValues.password,
            PlatformId: platformData.id
          })
        );
      }
    }
  };

  return (
    <form onSubmit={event => onSubmit(event)}>
      <h2 className="text-center text-3xl leading-9 font-bold">{titleText}</h2>
      {subText && (
        <p className="text-gray-800 text-lg text-center mt-4" data-testid="auth-form-subtext">
          {subText}
        </p>
      )}

      {loading ? (
        <div className="flex justify-center mt-6">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          {error && <p className="text-sm leading-5 font-medium text-red-800 mt-2">{error}</p>}
          {errorPassword && (
            <p className="text-sm leading-5 font-medium text-red-800 mt-4">{errorPassword}</p>
          )}

          {/* Show name field first when creating account */}
          {isAccountCreation && (
            <InputField
              name="name"
              label="Full name"
              value={formValues.name || ""}
              onChange={onChange}
              required
              autoFocus
              tabIndex={1}
              placeholder="Michael Scott"
            />
          )}

          {!(isOTPLoginChosen && isOTPSent) && (
            <InputField
              name="email"
              label="Email address"
              value={formValues?.email || ""}
              onChange={onChange}
              required
              autoFocus={!isAccountCreation}
              tabIndex={isAccountCreation ? 2 : 1}
              placeholder="michael@dundermifflin.com"
            />
          )}

          {(isAccountCreation || isPasswordUpdate || isPasswordLogin) && (
            <InputField
              name="password"
              label={isPasswordUpdate ? "New password" : "Password"}
              value={formValues.password || ""}
              onChange={onChange}
              type="password"
              required
              tabIndex={isAccountCreation ? 3 : 2}
              placeholder="••••••••"
            />
          )}

          {isPasswordLogin && !isPasswordUpdate && <ResetPasswordButton />}

          {isPasswordUpdate && (
            <InputField
              name="confirmPassword"
              label="Confirm password"
              value={formValues.confirmPassword || ""}
              onChange={onChange}
              type="password"
              required
              tabIndex={4}
              placeholder="••••••••"
            />
          )}

          {isOTPLoginChosen && isOTPSent && (
            <InputField
              name="otp"
              label="One-Time Code"
              value={formValues.otp || ""}
              onChange={onChange}
              type="text"
              required
              placeholder="123456"
            />
          )}

          {/* Main action button - only show for account creation, password login/update, or OTP verification, not initial OTP flow */}
          {(isAccountCreation ||
            isPasswordLogin ||
            isPasswordUpdate ||
            (isOTPLoginChosen && isOTPSent)) && (
            <button
              type="submit"
              className="mt-6 w-full flex justify-center py-3 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-platform-primary focus:outline-none transition duration-150 ease-in-out"
              tabIndex={5}
            >
              {isOTPLoginChosen && isOTPSent
                ? "Verify Code"
                : isAccountCreation
                ? "Create my Account"
                : isPasswordUpdate
                ? "Update Password"
                : "Sign In"}
            </button>
          )}

          {isOTPLoginChosen && isOTPSent && (
            <div className="mt-2 flex items-center justify-center text-sm leading-5">
              <button
                onClick={() => {
                  setFormValues({}); // clear form values
                  setIsOTPSent(false);
                  // document.querySelector("[name=email]").focus();
                }}
                type="button"
                className="font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:underline transition ease-in-out duration-150"
                tabIndex={6}
              >
                Try another email
              </button>
            </div>
          )}

          {/* Side-by-side buttons for initial login screen */}
          {!isAccountCreation &&
            !isPasswordUpdate &&
            !isPasswordLogin &&
            !(isOTPLoginChosen && isOTPSent) && (
              <div className="mt-6 flex items-center justify-between">
                <button
                  className="py-3 px-4 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none transition ease-in-out duration-150 w-[48%]"
                  onClick={() => {
                    onChoosePasswordLogin();
                  }}
                  type="button"
                  tabIndex={7}
                >
                  Enter Password
                </button>
                <button
                  type="submit"
                  className="py-3 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-platform-primary focus:outline-none transition ease-in-out duration-150 w-[48%]"
                  tabIndex={8}
                >
                  Send me One-Time Code
                </button>
              </div>
            )}

          {
            <div
              className={`mt-6 flex items-center justify-center ${isAccountCreation ? "" : "mt-8"}`}
            >
              {!isAccountCreation ? (
                <p className="text-sm leading-5">
                  Don't have an account?{" "}
                  <button
                    onClick={() => {
                      setAuthFlowChosen("signup");
                      document.querySelector("[name=email]").focus();
                    }}
                    type="button"
                    className="font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:underline transition ease-in-out duration-150"
                    tabIndex={9}
                  >
                    Create account
                  </button>
                </p>
              ) : (
                <p className="text-sm leading-5">
                  Already have an account?{" "}
                  <button
                    onClick={() => {
                      setAuthFlowChosen("otp");
                      document.querySelector("[name=email]").focus();
                    }}
                    type="button"
                    className="font-medium text-blue-600  hover:text-blue-500 focus:outline-none focus:underline transition ease-in-out duration-150"
                    tabIndex={8}
                  >
                    Sign in with One-Time Code
                  </button>
                </p>
              )}
            </div>
          }
        </>
      )}
    </form>
  );
};

export default AuthForm;
