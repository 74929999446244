import { useMemo } from "react";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { setLoginModalState } from "./authSlice";

// use this to summon up the auth modals easily
export function useAuthPrompt() {
  const dispatch = useDispatch();

  // Signin form (see AuthForm) supports signup flow as well.
  const promptSignin = useCallback(
    () =>
      dispatch(
        setLoginModalState({
          loginModalShowing: true,
          activeAuthForm: "signIn"
        })
      ),
    [dispatch]
  );

  const promptSignup = useCallback(
    () =>
      dispatch(
        setLoginModalState({
          loginModalShowing: true,
          activeAuthForm: "signUp"
        })
      ),
    [dispatch]
  );

  const promptSigninWithRedirect = useMemo(
    () =>
      (redirectOnCloseTo = null) =>
        dispatch(
          setLoginModalState({
            loginModalShowing: true,
            activeAuthForm: "signIn",
            redirectOnCloseTo
          })
        ),
    [dispatch]
  );

  const dismissAuthPrompt = useCallback(
    () =>
      dispatch(
        setLoginModalState({
          loginModalShowing: false,
          error: null
        })
      ),
    [dispatch]
  );

  return {
    promptSignin,
    promptSignup,
    promptSigninWithRedirect,
    dismissAuthPrompt
  };
}
