import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Transition } from "@tailwindui/react";
import AuthForm from "./AuthForm";
import ForgotPassword from "./ForgotPassword";
import ResetPasswordHeader from "./ResetPasswordHeader";
// import Socials from "./Socials";
import { useAuthPrompt } from "../../features/auth/authHooks";

const AuthModal = () => {
  const history = useHistory();
  const { dismissAuthPrompt } = useAuthPrompt();
  const { loginModalShowing, activeAuthForm, redirectOnCloseTo } = useSelector(state => state.auth);

  const [formValues, setFormValues] = useState({}); // form state

  // add escape key listener to close modal
  useEffect(() => {
    if (!loginModalShowing) return;
    const handleEsc = e => {
      if (e.key === "Escape") {
        dismissAuthPrompt();
        setFormValues({}); // clear form values
        if (redirectOnCloseTo) history.push("/");
      }
    };
    window.addEventListener("keydown", handleEsc);
    return () => window.removeEventListener("keydown", handleEsc);
  }, [loginModalShowing, dismissAuthPrompt, redirectOnCloseTo, history]);

  return (
    <Transition
      show={loginModalShowing}
      as="div"
      className="fixed inset-0 overflow-y-auto"
      style={{ zIndex: 50 }}
    >
      <div className="flex items-center justify-center min-h-screen">
        <Transition.Child
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          as="div"
          className="fixed inset-0 transition-opacity"
        >
          <div
            className="absolute inset-0 bg-gray-500 opacity-75"
            onClick={() => {
              dismissAuthPrompt();
              setFormValues({}); // clear form values
              if (redirectOnCloseTo) history.push("/");
            }}
          ></div>
        </Transition.Child>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
        &#8203;
        <Transition.Child
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          as="div"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
          className="w-full max-w-sm px-5 sm:px-auto sm:max-w-lg inline-block align-middle bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all"
        >
          <div className="bg-white flex flex-col justify-center py-8">
            {"resetPassword" === activeAuthForm && <ResetPasswordHeader link={true} />}
            {"resetPassword-no-link" === activeAuthForm && <ResetPasswordHeader link={false} />}

            <div className="mt-2 sm:mx-auto sm:w-full sm:max-w-md">
              {"signIn" === activeAuthForm && (
                <AuthForm formValues={formValues} setFormValues={setFormValues} />
              )}
              {"signUp" === activeAuthForm && (
                <AuthForm
                  formValues={formValues}
                  initialFlow="signup"
                  setFormValues={setFormValues}
                />
              )}

              {("resetPassword" === activeAuthForm ||
                "resetPassword-no-link" === activeAuthForm) && (
                <ForgotPassword email={formValues.email} />
              )}
            </div>
          </div>
        </Transition.Child>
      </div>
    </Transition>
  );
};

export default AuthModal;
